<template>
  <div id="userLayout" :class="['user-layout-wrapper', device]" style="background-color:red">
    <div class="container flex-c">
      <div class="top">
        <div class="header">
        </div>
      </div>

      <route-view></route-view>

      <div class="footer">
        <div class="copyright">
          Copyright &copy; 2024 <a href="#" target="_blank">深圳市旭亮欣基科技有限公司</a> 版权所有
           <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023054396号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteView from "@/components/layouts/RouteView"
import { mixinDevice } from '@/utils/mixin.js'

export default {
  name: "UserLayout",
  components: { RouteView },
  mixins: [mixinDevice],

  data() {
    return {
      yearNow: '3000'
    }
  },
  created() {
    this.setYear()
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  },
  methods:{
    setYear() {
      let year = new Date().getFullYear()
      console.log(year)
      this.yearNow = year

    }
  },
}
</script>

<style lang="less" scoped>
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 16px;
  margin: 48px 0 24px;
  text-align: center;

  a{
    color: rgba(255, 255, 255, 0.45) !important;
    font-weight: bolder;
  }
  /*.links {*/
  /*  margin-bottom: 8px;*/
  /*  font-size: 14px;*/
  /*  a {*/
  /*    color: rgba(255, 255, 255, 0.45) !important;*/
  /*    transition: all 0.3s;*/
  /*    font-weight: bolder;*/
  /*    &:not(:last-child) {*/
  /*      margin-right: 40px;*/
  /*    }*/
  /*  }*/
  /*}*/
  .copyright {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
  }
}
/*}*/
/*}*/

.main {
  height: 100%;
}
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    background: url(~@/assets/background-image.jpg) no-repeat center center;
    // background-color: #1689DC;
    /*background-image: linear-gradient(90deg, #3595f8 30%, #4cacff);*/
    // background-size: 120%;
    /*background-size: 100%;*/
    background-size:cover;
    padding: 0;
    position: relative;

    a {
      text-decoration: none;
    }

  }
}
</style>